<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="PICKUP_SETTINGS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="minHieght80 flex-col bg-primary-white mt-5 p-5">
      <div v-if="!loading">
        <PickupCheckbox
          v-for="(val, ind) in settingsArray"
          :key="ind"
          parent-module="pickup"
          class="mt-6"
          :title="ind"
          :modules-array="val"
          :table-head-array="tableHeadArray"
          @updateSettings="getSettings"
        />
      </div>
      <Loader v-else class="mt-28" :content="true" />
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import PickupCheckbox from '@src/components/Settings/attandance-settings/PermissionSettingsCheckbox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { removeExtraRolePermissions } from '@utils/permissions'
/* 3rd party Component */
import { mapActions, mapState } from 'vuex'
/**
 * @notes IMPORTANT NOTES
 * index ACTION FOR VIEW
 * create ACTION FOR Create
 * update ACTION FOR Edit
 * deactivate ACTION FOR delete
 * */

export default {
  components: {
    TitleWrapper,
    PickupCheckbox,
    Loader,
  },
  data: function () {
    return {
      settingsArray: [],
      tableHeadArray: ['Settings', 'View', 'Create', 'Edit', 'Delete'],
      loading: false,
      excludeRoleSettings: ['campus_moderator', 'section_moderator', 'section_admin'],
    }
  },
  page: {
    title: 'System Settings | Pickup Settings',
  },
  computed: {
    ...mapState('layout', [
      'currentCampusScope',
      'currentSectionScope',
      'activeRole',
      'currentClassScope',
    ]),
  },
  watch: {
    currentCampusScope: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    currentSectionScope: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    activeRole: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    currentClassScope: {
      handler() {
        this.getSettings()
      },
    },
  },

  async mounted() {
    this.getSettings()
  },

  methods: {
    async getSettings() {
      this.loading = true
      const [res, err] = await this.getPickupPermissionSettings()
      removeExtraRolePermissions(this.excludeRoleSettings, res.data)
      this.settingsArray = res.data
      this.loading = false
    },
    ...mapActions('settings', ['getPickupPermissionSettings']),
  },
}
</script>
